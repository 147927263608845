<template>
  <BasicCard :loading="loading" title="DID Assignment">
    <template v-slot:feature>
      <v-icon right x-large>mdi-account-circle</v-icon>
    </template>

    <v-alert type="success">This DID is active.</v-alert>

    <v-sheet
      class="px-3 pt-1 text--secondary"
      v-bind:class="{
                'blue-grey darken-5': $vuetify.theme.dark,
                'blue-grey lighten-5': !$vuetify.theme.dark,
            }"
    >
      <p
        class="subtitle-1 text--primary my-1"
        v-if="!loading && accountData.name"
      >
        {{ accountData.name }}
      </p>

      <v-row dense>
        <v-col cols="5">
          <span class="font-weight-bold">Trunk ID</span>
        </v-col>
        <v-col cols="7">
          {{ accountData.i_account }}
        </v-col>
        <v-col cols="5">
          <span class="font-weight-bold">Activated</span>
        </v-col>
        <v-col cols="7">
          {{ accountData.activation_date }}
        </v-col>
        <v-col cols="5">
          <span class="font-weight-bold">Customer</span>
        </v-col>
        <v-col cols="7">
          {{ accountData.customer_name }}
        </v-col>
      </v-row>
    </v-sheet>

    <template v-slot:actions>
      <ButtonWithTooltip
        text="Trunk"
        icon="mdi-eye"
        :to="{ name: 'TrunkSummary', params: { customerId: accountData.i_customer, trunkId: accountData.i_account}}"
        tooltip="View Trunk Details"
        flat
      />
      <ButtonWithTooltip
        text="Customer"
        icon="mdi-eye"
        :to="{
                    name: 'CustomerSummary',
                    params: { customerId: accountData.i_customer },
                }"
        tooltip="View Customer Details"
        flat
      />
    </template>
  </BasicCard>
</template>

<script>
import BasicCard from '../templates/BasicCard';
import ButtonWithTooltip from '../../pieces/ButtonWithTooltip';
import apiMixin from '../../../mixins/apiMixin';
export default {
  name: 'DIDAssignmentCard',
  components: { ButtonWithTooltip, BasicCard },
  mixins: [apiMixin],
  props: {
    data:{
      type: Object,
      default:null,
    },
    accountData: {
      type: Object,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({

  }),
  // methods: {
  //     async get(id) {
  //         this.data = [];
  //         this.isLoading = true;
  //         const response = await this.Api.send('get','trunks/' + id);
  //         this.isLoading = false;
  //         if (response.success) {
  //           //not sure why this is stored here.
  //             this.$store.commit('data', {
  //                 name: 'trunk',
  //                 data: response.data,
  //             });
  //             this.data = response.data;
  //         } else {
  //             this.data = [];
  //         }
  //     },
  // },
  // watch: {
  //     accountId: function (value) {
  //         this.get(value);
  //     },
  // },
  // created() {
  //     this.get(this.accountId);
  // },
};
</script>
