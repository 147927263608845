<template>
  <v-container fluid>
    <v-row dense>
      <!-- non-dismissable alert only shows if status is blocked -->
      <v-col cols="12" v-if="data != null && data.frozen === 'Y'">
        <Alert type="error" message="This number is frozen." />
      </v-col>
      <!-- DID Details Card -->
      <v-col cols="12" sm="6" lg="4" v-if="data != null">
        <DIDCard :data="data" :loading="isBusy" />
      </v-col>
      <!-- DID Assigment Card -->
      <v-col cols="12" sm="6" lg="4" v-if="data != null && accountData != null && data.i_master_account">
        <DIDAssignmentCard
          :data="data"
          :loading="isBusy"
          :account-data="accountData"
        />
      </v-col>
      <v-col cols="12" sm="6" lg="4" v-if="data != null">
        <DIDPricing :data="data" :loading="isBusy" />
      </v-col>
      <v-col cols="12" sm="6" lg="4" v-if="data != null && $store.state.user.isAdmin">
        <DIDCarrierCard :data="data" :loading="isBusy" />
      </v-col>
      <v-col cols="12" sm="6" lg="4" v-if="data != null && data.ipnd_details != null && data.i_account != null && !isBusy">
        <IPNDCard :ipnd-data="data.ipnd_details" :porta-account-data="this.accountData" card-type="ipnd"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Alert from '@/components/pieces/Alert';
import DIDCard from '../../components/cards/DID/DIDCard';
import DIDAssignmentCard from '../../components/cards/DID/DIDAssignmentCard';
import DIDPricing from '../../components/cards/DID/DIDPricing';
import DIDCarrierCard from '../../components/cards/DID/DIDCarrierCard';
import apiMixin from '../../mixins/apiMixin';
import IPNDCard from "../../components/cards/IPND/IPNDCard.vue";
export default {
  name: 'DIDDetail',
  mixins: [apiMixin],
  data: () => ({
    loadingCount: 0,
    reloadDIDList: false,
    newDIDAdded: false,
    newDIDAddedSuccess: false,
    data:null,
    dialogs: {
      newDID: false,
    },

    accountData: null,
  }),
  components: {
    DIDCarrierCard,
    DIDPricing,
    DIDAssignmentCard,
    DIDCard,
    Alert,
    IPNDCard
  },
  computed: {
    addDIDMessage: function () {
      return this.newDIDAdded && this.newDIDAddedSuccess
        ? 'DIDs are added successfully.'
        : 'There is an error while adding new DIDs. Please try again later.';
    },
    iMasterAccount: function( ){
      if(this.data != null && this.data.i_master_account){
        return this.data.i_master_account;
      }else{
        return null;
      }
    },
    isBusy() {
      return this.loadingCount > 0;
    },
  },
  watch: {
    iMasterAccount: function (value) {
      if(value != null) {
        this.getAccount(value);
      }
    },
  },
  methods: {
    async get(did) {
      this.loadingCount++;
      const response = await this.Api.send('post','dids/' + did + '/info');
      this.loadingCount--;
      if (response.success) {
        this.data = response.data;
      } else {
        this.data = null;
      }
    },
    async getAccount(id) {
      this.accountData = null;
      this.loadingCount++;
      const response = await this.Api.send('get','trunks/' + id);
      this.loadingCount--;
      if (response.success) {
        this.accountData = response.data;
      } else {
        this.accountData = null;
      }
    },
    update() {
      this.get(this.$route.params.did);
    },
  },
  created() {
    this.update();
  },
};
</script>
